.DashboardLayou {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 50px;
}

.EmployeeList {
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
  /* background-color: #ECF2F7; */
  overflow-x: hidden;
  padding: 10px;
  align-content: center;
  text-align: center;
  margin: auto;
  color: #282c34;
 
}

.EmployeeList .remainingRecord 
{
  overflow: scroll;
    height: 100vh;
}

.MainContainer {
  width: 100%;
  height: 100%;
  /* overflow-y: auto; */
  background-color: #ECF2F7;
  overflow-x: hidden;
  padding: 10px;
  align-content: center;
  text-align: center;
  overflow: hidden;
  margin: auto;
  color: #282c34;
  border: 10px #333 solid;
  background-image: radial-gradient(circle at 27% 33%, rgba(193, 193, 193,0.04) 0%, rgba(193, 193, 193,0.04) 50%,rgba(77, 77, 77,0.04) 50%, rgba(77, 77, 77,0.04) 100%),radial-gradient(circle at 35% 8%, rgba(183, 183, 183,0.04) 0%, rgba(183, 183, 183,0.04) 50%,rgba(25, 25, 25,0.04) 50%, rgba(25, 25, 25,0.04) 100%),radial-gradient(circle at 93% 50%, rgba(218, 218, 218,0.04) 0%, rgba(218, 218, 218,0.04) 50%,rgba(127, 127, 127,0.04) 50%, rgba(127, 127, 127,0.04) 100%),linear-gradient(90deg, hsl(116,0%,99%),hsl(116,0%,99%));
}

.ItemTitle {
  color: black;
  font-weight: bold;
}

/* style={{
  width: '200px',
  height: '100vh',
  alignContent: 'center',
  margin: 'auto',
  alignItems: 'center'
}} */

.employeeInfo .ant-col-8 {
  width: 100%;
  float: none;
}

.ant-row {
  text-align: left;
}

.ant-avatar > img {
  width: 100%;
  /* height: auto; */
  /* transform: translate(0px, 55px); */
  top: 0;
  position: relative;
}

.ant-list-item-meta-avatar .ant-avatar-lg {
  width: 100px;
  height: 100px;
  font-size: 64px;
}

.anticon svg {
  display: inline-block;
  vertical-align: middle;
  padding-top: 15px;
}

.employeeCard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 6px -5px rgba(0, 0, 0, 0.1), 0 0px 5px -2px rgba(0, 0, 0, 0.04);
  transition-property: color, background-color, box-shadow, transform;
  transition-duration: .15s;
  padding:9px;
  height: 100%;
}

.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 50px;
  line-height: 60px;
  margin: 15px 0;
}

.employeeDesignation {
  font-size: 20px;
  display: block;
  font-weight: 400;
  line-height: 1.5;
}

.ant-list * {
  text-align: center;
}

.employeeInfo .ant-list-item-meta {
  display: block;
}

.ant-list-item-meta-avatar {
  width: 100%;
  display: block;
  position: relative;
}

.ant-list-item-meta-content {
  display: block;
  width: 100%;
}

.ant-list-item-meta-title {
  margin: 30px 0 0;
}

.employeeInfo .ant-list-item-meta-description {
  position: absolute;
  top: -45px;
  background: #FD7200;
  padding: 5px 15px;
  color: #fff;
  border-radius: 100px;
  right: -25px;
}

.employeeInfo .ant-list-item-meta-description span {
  padding-top: 0;
  margin-top: -1px;
  display: block;
}

.employeeCardDetail p {
  position: absolute;
  top: 0;
  background: #fff8c5;
  left: 0;
  /* padding: 39px; */
  border-radius: 10% 70% 100% 0% / 10% 0% 100% 100%;
  height: 100px;
  width: 100px;
}

.employeeCardDetail p span {
  margin-left: -25%;
  display: block;
  margin-top: 20%;
  font-size: 24px;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}

/* .employeeCardDetail p {
  position: absolute;
  top: 10%;
  background: #e0e3e6;
  left: 40%;
  padding: 0;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  text-align: center;
}

.employeeCardDetail p span {
  margin-left: -45%;
  display: block;
  margin-top: -19%;
  font-size: 15px;
  color: #333;
  font-weight: bold;
  vertical-align: middle;
  padding-top: 8px;
} */

.cardNumber {
  margin-top: 10px;
  padding: 5px 30px;
  background: #f5ddce;
  display: inline-block;
  border-radius: 50px;
  font-weight: bold;
  letter-spacing: 2px;
  position:relative;
}

.employeeRecord {
  font-size: 18px;
  color: #e06f31;
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ant-list-header h2 {
  font-size: 35px;
}

.ant-list-split .ant-list-header {
  border-bottom: none;
}

.ItemTitle {
  color: #333;
  font-weight: bold;
}

.ant-badge svg {
  padding-top: 0;
}

.employeeInfo .ant-badge .anticon-crown {
  color: rgb(247, 190, 22);
  font-size: 50px;
  text-align: center;
  right: 50%;
  top: -13%;
}

.employeeInfo .ant-badge .anticon-star {
  color: rgb(247, 190, 22);
  font-size: 20px;
  right: 20%;
  top: 10%;
  background: #fff;
  border-radius: 100px;
  padding: 10px;
  /* box-shadow: 0 0 0 rgba(204,169,44, 0.5);
    animation: pulse 2s infinite; */
}

.employeeInfo .ant-badge .anticon-star svg {
  animation: beat .50s infinite alternate;
}
.remainingRecord .employeeInfo .ant-badge .anticon-star 
{
  right: 5%;
    top: 30%;
}

.latestRecord .employeeInfo .ant-badge .anticon-star 
{
 font-size: 40px;
}

/* @keyframes beat{
	to { transform: scale(1.4); }
} */

/* @-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.5);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.5);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
} */

.employeeInfo .ant-avatar svg {
  padding-top: 15px;
}

.employeeBirthday {
  font-size: 16px;
  position: absolute;
  top: 38%;
  left: 35%;
  background: red;
  color: #fff;
  padding: 5px 20px;
  border-radius: 100px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 300% 300%;
  animation: gradientBG 15s ease infinite;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-style: italic;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.employeeAnniversary {
  font-size: 16px;
  position: absolute;
  top: 54%;
  
  background: red;
  color: #fff;
  padding: 5px 20px;
  border-radius: 100px;
  background: linear-gradient(162deg, #4e43df, #d94838, #ac27d4);
  background-size: 300% 300%;
  -webkit-animation: AnimationName 15s ease infinite;
  -moz-animation: AnimationName 15s ease infinite;
  -o-animation: AnimationName 15s ease infinite;
  animation: AnimationName 15s ease infinite;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-style: italic;
}


.latestRecord  .employeeAnniversary {
  font-size: 30px;
  top: 86%;
  color: #fff;
  padding: 5px 25px;
  
}
.remainingRecord   .employeeAnniversary {
  font-size: 16px;
  padding: 5px 20px;
  top: 76%;
  
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-o-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.birthdayMainDiv, .anniversaryMainDiv {
  border-width: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: none;
  height: 100%;
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
  width: auto;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.abc {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0px 6px -5px rgba(0, 0, 0, 0.1), 0 0px 5px -2px rgba(0, 0, 0, 0.04);
  transition-property: color, background-color, box-shadow, transform;
  transition-duration: .15s;
  padding: 60px 40px 20px 40px;
  height: calc(100vh - 160px);
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px, rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;

}
.employeeCardDetail
{
  position: absolute;
  top: 0;
  left: 0;
}
.birthdayMainDiv .employeeCardDetail p , .anniversaryMainDiv .employeeCardDetail p {
  top: 0;
    left: 0;
}

.EmployeeList .ant-row {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.EmployeeList .ant-row .ant-list-item {
  height: auto;
}
.copyNumber
{


  border: none;
 
  font-size: 16px;
  position: absolute;
    top: 0;
    right: -40px;
    background: #faf2ec;
    border-radius: 100px;
    padding: 8px 8px;
    line-height: 0;
    color: #be8a69;
    cursor: pointer;
}
.copyNumber svg {

padding-top:0;

}
.cardInput
{
 position: absolute;
 top:-300px;
}


.latestRecord .ant-col-24
{
  display: none;
}

.latestRecord .ant-col-24:first-child
{
  display: block;
 
   
    justify-content: center;
    align-items: center;
}

.latestRecord .ant-col-24
{
  display: none;
}

.latestRecord .abc 
{
  height: calc(100vh - 80px);
  padding:20px 40px 20px 40px;
}

.latestRecord .abc  .employeeInfo
{
  height: 60vh;  
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
}

.latestRecord .ant-list-item-meta-title {
 
  font-size: 3.3vw;
  line-height: 1.1;
 
}
.latestRecord .employeeDesignation {
  font-size: 2vw;
    padding-top: 10px;
}
.latestRecord .ant-list-item-meta-avatar .ant-avatar-lg {
  width: 40vh;
  height: 40vh;
  font-size: 12vw;
  display: flex;
    justify-content: center;
    align-items: center;
}

.latestRecord .employeeRecord {
  font-size: 2.7vw;
 
  
}
.latestRecord .cardNumber {
  margin-top: 20px;
 
}
.latestRecord  .employeeCardDetail p {
 
  height: 160px;
  width: 160px;
}

.latestRecord  .employeeCardDetail p span {
 
  margin-top: 15%;
  font-size: 45px;
  
}
.latestRecord .employeeInfo .ant-list-item-meta-description
{
  font-size: 20px;
  padding: 10px 20px;
}

.latestRecord  .flowOut  .employeeCardDetail p
{
 
  background: #f7d6d6;
}
.latestRecord  .flowIn  .employeeCardDetail p
{
 
  background: #d5f1e8;
}
.latestRecord  .employeeInfo .ant-list-item-meta-description {

  top: -10px;
  right: -5px;
 
}
.latestRecord  .employeeRecordMain {
  vertical-align: bottom;
  /* margin-top: 0; */
  position: absolute;
  bottom: 3vh;
  left: 0;
  right: 0;
}

.remainingRecord .ant-col-24:first-child
{
  display: none;
}

.remainingRecord .employeeInfo .ant-col-24
{
  display: block;
}

.remainingRecord .abc 
{
  height: calc(50vh - 60px);
  padding: 20px 40px 20px 40px;
}

.remainingRecord .ant-list-item-meta-title
{
  font-size: 35px;
  line-height: 1.5;
  margin-top: 5px;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.remainingRecord .flowOut  .employeeCardDetail p
{
  height: 80px;
  width: 80px;
  background: #f7d6d6;
}
.remainingRecord .flowIn  .employeeCardDetail p
{
  height: 80px;
  width: 80px;
  background: #d5f1e8;
}

.remainingRecord .employeeDesignation {
  font-size: 20px;
  
}

.remainingRecord .employeeRecord {
  font-size: 20px;
  margin-top: 0;
 
}

.remainingRecord .ant-list-grid .ant-list-item
{
  margin-bottom: 10px;
}

.remainingRecord  .employeeInfo .ant-avatar svg
{
  padding-top: 5px;
}
.remainingRecord  .ant-list-split .ant-list-item:last-child
{
  margin-bottom: 22px;
}
.remainingRecord .ant-list-item-meta-avatar .ant-avatar-lg {
 

  vertical-align: middle;
    width: 15vh;
    height: 15vh;
    font-size: 6vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.remainingRecord .employeeInfo .ant-list-item-meta-description {
  
  top: 2px;
 
}

.remainingRecord   .employeeRecordMain {
  vertical-align: bottom;
  /* margin-top: 0; */
  position: absolute;
  bottom: 2vh;
  left: 0;
  right: 0;
}














.flowOut 
{
  /* background-color: #fb5a5a; */
  /* border-radius: 20px; */
  background-color: #ffbdbd;
}
.flowOut .abc
{
  background-color: #fbf4f4;
}

/* .flowOut .abc 
{
  background: #ffeaea;
  

} */


.flowOut .abc  .employeeInfo .ant-list-item-meta-description {
 
  background: #fb5a5a;
 
}
.flowOut .abc .ant-list-item-meta-avatar .ant-avatar-lg
{
  background-color: #fb5a5a;
  vertical-align: middle;
}

.flowOut .abc .ItemTitle {
  color: #c90505;
  font-weight: bold;
}
.flowOut .abc .cardNumber {

  background: #fec8c8;
  
}

.flowOut .abc .copyNumber {
  background: #FA2221;
  color: #fff;
 
}

.flowOut .abc .ant-list-item-meta-avatar .ant-avatar-lg
{
  background-color: #fb5a5a !important;
  vertical-align: middle;
}
.flowOut .abc .employeeRecord {
  color: #eb0404;
  font-weight: bold;
}

















.flowIn 
{
  /* background-color: #00c88a; */
  /* border-radius: 20px; */
  background-color: #4ce9b8;
}

.flowIn .abc 
{
  background-color: #edfff9;
  

}


.flowIn .abc  .employeeInfo .ant-list-item-meta-description {
 
  background: #00c88a;
 
}
.flowIn .abc .ant-list-item-meta-avatar .ant-avatar-lg
{
  background-color: #00c88a !important;
  vertical-align: middle;
}

.flowIn .abc .ItemTitle {
  color: #026c4b;
  font-weight: bold;
}
.flowIn .abc .employeeRecord {
  color: #009567;
  font-weight: bold;
}
.flowIn .abc .cardNumber {

  background: #b6f9e4;
  
}

.flowIn .abc .copyNumber {
  background: #05bf86;
  color: #fff;
 
}

.birthdayMainDiv .abc .ant-list-item-meta-avatar
{
  position: relative;
  display: inline-block;
}
.birthdayMainDiv .abc , .anniversaryMainDiv .abc
{
  background-image: url("assets/img/p.png");
  background-size: contain;
}

.birthdayMainDiv .abc .ant-list-item-meta-avatar:before
{

 content: url("assets/img/bb.png");
 height: 69px;
    width: 64px;
    position: absolute;
    top: -52px;
    z-index: 10;
    left: 60px;
    right: 0;
    margin: auto;
    transform: rotate(20deg);
}
/* .birthdayMainDiv:before, .anniversaryMainDiv:before
{

 content: url("assets/img/aa.png");
 height: 80px;
 width: 300px;
 position: absolute;
 top: 9px;
 z-index: 0;
 left: 0;
 right: 0;
 margin: auto;
} */
/* .birthdayMainDiv .abc .ant-list-item-meta-avatar:after
{

 content: url("assets/img/a.png");
 height: 64px;
    width: 64px;
    position: absolute;
    top: 38px;
    z-index: 0;
    left: -120px;
    right: 0;
    margin: auto;
} */

.anniversaryMainDiv  .abc .ant-list-item-meta-avatar
{
  position: relative;
  display: inline-block;
}

.anniversaryMainDiv  .abc .ant-list-item-meta-avatar:before
{

 content: url("assets/img/crown.png");
 height: 64px;
 width: 64px;
 position: absolute;
 top: -45px;
 z-index: 10;
 left: 0;
 right: 0;
 margin: auto;
}




.ant-list-item-meta-avatar
{
  margin-right: 0 !important;
  display: flex;
    justify-content: center;
}
.employeeRecordMain
{
  vertical-align: bottom;
  margin-top: auto;
}
.recordDate
{

padding: 15px 0;
font-size: 1.7vw;

    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
}
.remainingRecord .recordTime
{
  font-size: 1.4vw;
}
.remainingRecord .recordDate
{
  font-size: 20px;
  padding: 5px 0;
}


* {
  scrollbar-width: thin;
  scrollbar-color: white #c3c3c3;
  
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #c3c3c3;
  border-radius: 20px;
  
}

*::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: 3px solid #dfdfdf;
  
}